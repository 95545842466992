import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";

const DataProtection = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Data Protection" />
    <div class="content has-text-centered columns flex-wrap mar-top-160">
            <h2 class="text-block-17 column is-12 is-paddingless">Data Protection Addendum</h2>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
            This Data Protection Addendum ("DPA”) forms part of the Infeedo Private Limited  (“Company”) License Agreement including its appendices (“Master Agreement” or “Agreement’) with (“Customer”) and is subject to the terms and conditions of the Master Agreement. Terms not defined herein shall have the meaning set forth in the Master Agreement. In the event of a conflict between the Master Agreement and this DPA, with respect to Data Protection, this DPA shall prevail.
            <br/><br/>
            This DPA to the Master Agreement including its appendices will, as from the amendment Effective Date (as defined below), be effective and replace any previously applicable data privacy provisions or any terms previously applicable to privacy, data processing and/or data security.
            <br/><br/>
            Except as modified below, the terms of the Master Agreement shall remain in full force and effect.
            <br/><br/>
            In consideration of the mutual obligations set out herein, the parties hereby agree that the terms and conditions set out below shall be added as an Addendum to the Master Agreement. Except where the context requires otherwise, references in this Addendum to the Master Agreement are to the Master Agreement as amended by, and including, this Addendum.
            <br/><br/>
            <ol className="mg-left-1">
                <li>
                    <p><b className="has-text-dark"> Introduction</b></p>
                    <p>This DPA reflects the parties’ agreement with respect to the terms governing the processing and security of Customer Personal Data under the applicable Master Agreement.</p>
                </li>
                <br/>
                <li>
                    <p><b className="has-text-dark">Definitions</b></p>
                    <p>2.1. Capitalized terms used but not defined in this DPA have the meanings given elsewhere in the applicable Master Agreement. In this DPA, unless stated otherwise:</p>
                    <p><b>"Addendum Effective Date”</b> means the date on which Customer clicked to accept or the parties otherwise agreed to this Data Protection Addendum in respect of the applicable Agreement</p>
                    <p><b>“Customer Data”</b> means data submitted, stored, sent or received via for the provision of Services by Customer, its Affiliates.</p>
                    <p><b>“Customer Personal Data”</b> means personal data contained within the Customer Data and as otherwise defined in GDPR.</p>
                    <ul>
                        <li><b>“Data Incident”</b> means a breach of Processor’s security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Customer Data on systems managed by or otherwise controlled by Processor. “Data Incidents” will not include unsuccessful attempts or activities that do not compromise the security of Customer Data, including unsuccessful log-in attempts, pings, port scans, denial of service attacks, and other network attacks on firewalls or networked systems. </li>
                        <br/>
                        <li><b>“DPA”</b> means this Data Protection Agreement. </li>
                        <br/>
                        <li><b>“EEA”</b> means the European Economic Area. </li>
                        <br/>
                        <li><b>"European Union Data Protection Legislation"</b> means EU Directive 95/46/EC, as transposed into domestic legislation of each Member State and as amended, replaced or superseded from time to time, including by the GDPR and laws implementing or supplementing the GDPR;</li>
                    </ul>
                    <p><b>“Security Incident”</b> means a breach of data security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Customer Data on systems managed by or otherwise controlled by the Company. “Security Incidents” will not include unsuccessful attempts or activities that do not compromise the security of Customer Data, including unsuccessful log-in attempts, pings, port scans, denial of service attacks, and other network attacks on firewalls or networked systems.</p>
                    <p><b>“GDPR”</b> means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.</p>
                    <p><b>“Information Security Obligations”</b> means commercially reasonable and appropriate physical, technical and organisational security measures, including those set forth in the Agreement, along with its Schedules and Appendix 2 to the Standard Contractual Clauses of Appendix 2.</p>
                    <p><b>“Model Contract Clauses”</b> means the standard data protection clauses set out in Annexure 2 for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection, as described in Article 46 of the GDPR.</p>
                    <p><b>“Non-European Data Protection Legislation”</b> means data protection or privacy legislation other than the European Data Protection Legislation.</p>
                    <p><b>"Personal Data"</b> means any information relating to an identified or identifiable natural person (or, to the extent that Data Privacy Laws apply to information about legal persons, an identified or identifiable legal person) or as otherwise defined in Data Privacy Laws.</p>
                    <p><b>“Process”</b> means any operation, or set of operations, which is performed upon Personal Data, whether or not by automatic means, such as collection, recording, organisation, storage, adaptation or alteration, access to, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, blocking, erasure or destruction. "Processes" and “Processing” shall be construed accordingly. Processing includes sub-Processing. </p>
                    <p><b>“Sub-processors”</b> means third parties authorized under this DPA to have logical access to and process Customer Data in order to provide parts of the Services and related technical support.</p>
                    <p><b>“Term”</b> means the period from the Addendum Effective Date until the end of Company’s provision of the Services under the applicable Master Agreement, including, if applicable, any period during which provision of the Services may be suspended and any post-termination period during which the Company may continue providing the Services for transitional purposes.</p>
                    <p>2.2. The terms “personal data”, “data subject”, “processing”, “controller”, “processor” and “supervisory authority” as used in this DPA have the meanings given in the GDPR, and the terms “data importer” and “data exporter” have the meanings given in the Model Contract Clauses, in each case irrespective of whether the European Data Protection Legislation or Non-European Data Protection Legislation applies.</p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Duration of DPA</b><br/><br/>
                    <p>This DPA will take effect on the Effective Date and, notwithstanding expiry of the Term, remain in effect until, and automatically expire upon, deletion of all Customer Data by Company as described in this DPA or as otherwise agreed between the parties.</p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Scope of Data Protection Legislation.</b><br/><br/>
                    <p>4.1 Application of European Data Protection Laws. The parties acknowledge and agree that, in respect of the Services provided by the Company under the Master Agreement, the European Union Data Protection Legislation will apply to the processing of Customer Personal Data if:</p>
                    <ul>
                        <li>(a) the processing is carried out in the context of the activities of an establishment of Customer in the territory of the EEA; and/or</li>
                        <li>(b) the Customer Personal Data is personal data relating to data subjects who are in the EEA and the processing relates to the offering to them of goods or services in the EEA or the monitoring of their behaviour in the EEA.</li>
                    </ul>
                    <p>4.2 Application of Non- European Data Protection. The parties acknowledge that while Non-European Data Protection Legislation may also apply to the processing of Customer Personal Data the parties agree to comply with their respective obligations under the GDPR.</p>
                    <p>4.3 Application of this DPA. Except to the extent this DPA states otherwise or as required by law, the terms of this DPA will apply irrespective of whether the European Data Protection Legislation or Non-European Data Protection Legislation applies to the processing of Customer Personal Data.</p>
                </li>
                <br/>
                <li>
                    <b className="has-text-dark">Processing of Data.</b><br/><br/>
                    <p>5.1 <b>Roles and Regulatory Compliance; Authorization. </b></p>
                    <p>5.1.1. The parties acknowledge and agree that with regard to the Processing of Personal Data in respect of the Services under the Master Agreement, Customer is the Controller, Company is the Processor.</p>
                    <p>5.1.2. Controller and Processor Responsibilities. In the event the European Data Protection Legislation applies to the processing of Customer Personal Data, the parties acknowledge and agree that:</p>
                    <p>(a) the subject matter and details of the processing are described in <b>Annexure 1</b> to this DPA;</p>
                    <p>(b) each party will comply with the obligations applicable to it under the European Data Protection Legislation with respect to the processing of that Customer Personal Data.</p>
                    <p>5.1.3. Customer’s Processing of Personal Data. Company shall in its use of the Services Process Personal Data in accordance with, and the extent required by, the requirements of European Data Protection Legislation. For the avoidance of doubt, Customer’s instructions for the Processing of Personal Data shall comply with the European Data Protection Legislation. Customer shall have sole responsibility for the accuracy, quality, and legality of Personal Data and the means by which Customer acquired Personal Data. </p>
                    <p>5.2 <b>Scope of Processing</b></p>
                    <p>5.2.1 <b>Customer’s Instructions.</b> By entering into this DPA, Customer instructs the Company to process Customer Personal Data only in accordance with the European Data Protection Legislation: </p>
                    <p>(a) to provide the Services; </p>
                    <p>(b) as further specified via Customer’s use of the Services (including various functionality of the Services); </p>
                    <p>(c) as documented in the form of the applicable Master Agreement, including this DPA; and</p>
                    <p>(d) as further documented in any written instructions given by Customer and acknowledged by Company as constituting instructions for purposes of this DPA.</p>
                    <p>5.2.2. <b>Company’s Compliance with Instructions.</b> Company will comply with the Customer’s Instructions (including with regard to data transfers) as specified under Clause 5.2.1.</p>
                    <p>The Parties hereto understand and agree that the Software and the services provided herein do not fall in the category of “automated decisions”. For purposes hereof “automated decision” shall mean a decision by the Controller which produces legal effects concerning a data subject or significantly affects a data subject and which is based solely on automated processing of personal data intended to evaluate certain personal aspects relating to him, such as his performance at work, creditworthiness, reliability, conduct, etc. </p>
                </li>
                <br/>
                <li>
                    <b>Data Deletion.</b><br/><br/>
                    <p>6.1. <b>Deletion During Term</b></p>
                    <p>Company will enable Customer (by way of informing the Company) to delete Customer Data during the applicable Term in a manner consistent with the functionality of the Services. If Customer uses the Services to delete any Customer Data during the applicable Term and the Customer Data cannot be recovered by Customer, this will be deemed to constitute an instruction to Company to delete the relevant Customer Data from its systems in accordance with European Data Protection Legislation. Company will comply with this instruction as soon as reasonably practicable and within a maximum period of 180 days, unless Customer requires storage for a longer period and Customer informs Company of that requirement in writing within 30 days of Company’s receipt of any instruction to delete Customer Data. </p>
                    <p>6.2. <b>Deletion on Term Expiry</b></p>
                    <p>Subject to Clause 6.3 (Deferred Deletion Instruction) below, on expiry of the applicable Term Customer instructs Company to delete all Customer Data (including existing copies) from Company’s systems in accordance with European Data Protection Legislation. Company will comply with this instruction as soon as reasonably practicable and within a maximum period of 180 days, unless Customer requires storage for a longer period and Customer informs Company of that requirement in writing within 30 days of Company’s receipt of any instruction to delete Customer Data. Without prejudice to Section 9.1 (Access; Rectification; Restricted Processing; Portability), Customer acknowledges and agrees that Customer will be responsible for exporting, before the applicable Term expires, any Customer Data it wishes to retain post deletion.</p>
                    <p>6.3. Deferred Deletion Instruction. To the extent any Customer Data covered by the deletion instruction described in Section 6.2 (Deletion on Term Expiry) is also processed, when the applicable Term under Section 6.2 expires, in relation to an Agreement with an extended term, such deletion instruction will only take effect with respect to such Customer Data when the extended term expires. For clarity, this DPA will continue to apply to such Customer Data until its deletion by the Company.</p>
                </li>
                <br/>
                <li>
                    <p><b>Data Security.</b></p>
                    <p>7.1. Company’s Security Measures, Controls and Assistance. </p>
                    <p>7.1.1. Company’s Security Measures. Company will implement and maintain adequate technical and organizational measures to protect Customer Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access as required by the European Data Protection Legislation and described in <b>Appendix 2</b> (the “Security Measures”). As described in Appendix 2, the Security Measures include measures to protect and encrypt personal data; to help ensure ongoing confidentiality, integrity and availability of Company’s systems and services; to help restore timely access to personal data following an incident; and for regular testing of effectiveness. Company may update or modify the Security Measures from time to time.</p>
                    <p>7.1.2. Security Compliance by Company Personnel. Company will take appropriate steps to ensure compliance with the Security Measures by its employees, contractors and Sub-processors to the extent applicable to their scope of performance under the applicable Master Agreement. The Company also agrees to ensure that all persons authorized to process Customer Personal Data have committed themselves to confidentiality and have executed appropriate documentation to maintain confidentiality.</p>
                    <p>7.1.3. Additional Controls. In addition to the Security Measures, Company will make Additional Controls available to: (a) allow Customer to take steps to secure Customer Data; and (b) provide Customer with information about securing, accessing and using Customer Data. Further, Company will (taking into account the nature of the processing of Customer Personal Data and the information available to Company) assist Customer in ensuring compliance with any of Customer’s obligations in respect to security of personal data and data breaches.</p>
                    <p>7.2. <b>Data Security Incidents.</b></p>
                    <p>7.2.1. Incident Notification. If Company becomes aware of a Data Incident, Company will: (a) notify Customer of the Data Incident promptly and without undue delay; and (b) promptly take reasonable steps to minimize harm and secure Customer Data.</p>
                    <p>7.2.2. Details of Data Incident. Data Incident Notifications will describe, to the extent possible, details of the Data Incident, including steps taken to mitigate the potential risks and steps Company recommends Customer take to address the Data Incident.</p>
                    <p>7.2.3. Delivery of Notification. Notification(s) of any Data Incident(s) will be delivered to the Notification Email Address or, at Company’s discretion, by direct communication (for example, by phone call). Customer is solely responsible for ensuring that the Notification Email Address is current and valid.</p>
                    <p>7.2.4. No Acknowledgment of Fault by Company. Company’s notification of or response to a Data Incident under this Section 7.2 (Data Incidents) will not be construed as an acknowledgement by Company of any fault or liability with respect to the Data Incident.</p>
                    <p>7.3. <b>Customer’s Security Responsibilities and Assessment.</b></p>
                    <p>7.3.1. Customer’s Security Responsibilities. Customer agrees that, without prejudice to Company’s obligations under Section 7.1 (Company’s Security Measures and Controls) and Section 7.2 (Data Incidents):</p>
                    <p>(a) Customer is solely responsible for its use of the Services, including:</p>
                    <p>(i) making appropriate use of the Services and the Additional Controls to ensure a level of security appropriate to the risk in respect of the Customer Data;</p>
                    <p>(ii) securing the account authentication credentials, systems and devices Customer uses to access the Services; and</p>
                    <p>(iii) backing up its Customer Data; and</p>
                    <p>(b) Company has no obligation to protect Customer Data that Customer elects to store or transfer outside of Company’s and its Sub-processors’ systems.</p>
                    <p>7.3.2. Customer’s Security Assessment.</p>
                    <p>(a) Customer is solely responsible for reviewing the Security Documentation and evaluating for itself whether the Services, the Security Measures, the Additional Controls and Company’s commitments under this Section 7 (Data Security) will meet Customer’s needs, including with respect to any security obligations of Customer under the European Data Protection Legislation.</p>
                    <p>(b) Customer acknowledges and agrees that (taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing of Customer Personal Data as well as the risks to individuals) the Security Measures implemented and maintained by Company as set out in Section 7.1.1 (Company’s Security Measures) provide a level of security appropriate to the risk in respect of the Customer Data.</p>
                    <p>7.4. Security Certifications and Reports. Company will do the following to evaluate and help ensure the continued effectiveness of the Security Measures:</p>
                    <p>(a) update the SOC 2 Report at least once every 18 months.</p>
                    <p>7.5. <b>Reviews and Audits of Compliance.</b></p>
                    <p>7.5.1. Reviews of Security Documentation. In addition to the information contained in the applicable Master Agreement including this DPA, Company will make available for review by Customer the following documents and information to demonstrate compliance by Company with its obligations under this DPA:</p>
                    <p>(a) the then-current SOC 2 Report, following a request by Customer in accordance with Section 7.5.3(a).</p>
                    <p>7.5.2. Customer’s Audit Rights.</p>
                    <p>(a) If the European Data Protection Legislation applies to the processing of Customer Personal Data, Company will allow Customer at Customer’s own expense or an independent auditor appointed by Customer at Customer’s own expense to conduct audits (including inspections) to verify Company’s compliance with its obligations under this DPA, only limited to Customer’s information. Company will contribute to such audits as described in Section 7.4 (Security Certifications and Reports) and this Section 7.5 (Reviews and Audits of Compliance).</p>
                    <p>(b) If Customer has entered into Model Contract Clauses as described in Section 10.2 (Transfers of Data Out of the EEA), Company will, without prejudice to any audit rights of a supervisory authority under such Model Contract Clauses, allow Customer or an independent auditor appointed by Customer to conduct audits as described in the Model Contract Clauses, at Customer’s own expense, and only limited to Customer’s Data.</p>
                    <p>(c) Customer may also conduct an audit to verify Company’s compliance with its obligations under this DPA by reviewing the Security Documentation (which reflects the outcome of audits conducted by Company’s Third Party Auditor).</p>
                </li>
                <br/>
                <li>
                    <p><b>Impact Assessments and Consultations.</b></p>
                    <p>Customer agrees that Company will (taking into account the nature of the processing and the information available to Company) assist Customer in ensuring compliance with any obligations of Customer in respect of data protection impact assessments and prior consultation, including if applicable Customer’s obligations pursuant to Articles 35 and 36 of the GDPR, by:</p>
                    <p>(a) providing the Additional Controls in accordance with Section 7.1.3 (Additional Controls) and the Security Documentation in accordance with Section 7.5.1 (Reviews of Security Documentation); and</p>
                    <p>(b) providing the information contained in the applicable Master Agreement including this DPA.</p>
                </li>
                <br/>
                <li>
                    <p><b>Data Subject Rights; Data Export.</b></p>
                    <p>9.1. Access; Rectification; Restricted Processing; Portability. During the applicable Term, Company will, in a manner consistent with the functionality of the Services, enable Customer to access, rectify and restrict processing of Customer Data, including via the deletion functionality provided by Company as described in Section 6.1 (Deletion During Term), and to export Customer Data.</p>
                    <p>9.2 <b>Data Subject Requests.</b></p>
                    <p>9.2.1. Customer’s Responsibility for Requests. During the applicable Term, if Company receives any request from a data subject in relation to Customer Personal Data, Company will advise the data subject to submit his/her request to Customer, and Customer will be responsible for responding to any such request including, where necessary, by using the functionality of the Services.</p>
                    <p>9.2.2. Company’s Data Subject Request Assistance. Customer agrees that (taking into account the nature of the processing of Customer Personal Data) Company will assist Customer in fulfilling any obligation to respond to requests by data subjects, including if applicable Customer’s obligation to respond to requests for exercising the data subject’s rights laid down in Chapter III of the GDPR, by:</p>
                    <p>(a) providing the Additional Controls in accordance with Section 7.1.3 (Additional Controls); and</p>
                    <p>(b) complying with the commitments set out in Section 9.1 (Access; Rectification; Restricted Processing; Portability) and Section 9.2.1 (Customer’s Responsibility for Requests).</p>
                </li>
                <br/>
                <li>
                    <p><b>Data Transfers.</b></p>
                    <p>10.1. <b>Data Storage and Processing Facilities</b></p>
                    <p>Customer agrees that Company may, subject to Section 10.2 (Transfers of Data Out of the EEA), store and process Customer Data countries in which Company or any of its Sub-processors maintains facilities. For the purposes of this Addendum and the Master Agreement, the Customer understands and agrees that the Company maintains its servers in the AWS facility at Virginia, United States of America. And until such time that the servers are in the United States of America, Clause 10.2 shall not be applicable. </p>
                    <p>10.2. <b>Transfers of Data Out of the EEA.</b></p>
                    <p>10.2.1. Company’s Transfer Obligations. If the storage and/or processing of Customer Personal Data (as set out in Section 10.1 (Data Storage and Processing Facilities)) involves transfers of Customer Personal Data out of the EEA and the European Data Protection Legislation applies to the transfers of such data, Company will if requested to do so by Customer, ensure that Company as the data importer of the transferred Personal Data enters into Model Contract Clauses with Customer as the data exporter of such data, and that the transfers are made in accordance with such Model Contract Clauses as set out as Annexure II herewith.</p>
                    <p>10.2.2 Customer’s Transfer Obligations. In respect of transferred Personal Data, Customer agrees that if under the European Data Protection Legislation Company reasonably requires Customer to enter into Model Contract Clauses in respect of such transfers, Customer will do so.</p>
                    <p>10.3. <b>Data Center Information</b></p>
                    <p>Information about the locations of Company data centers is available on request.</p>
                    <p>10.4. <b>Disclosure of Confidential Information Containing Personal Data</b></p>
                    <p>If Customer has entered into Model Contract Clauses as described in Section 10.2 (Transfers of Data Out of the EEA), Company will, notwithstanding any term to the contrary in the applicable Master Agreement, ensure that any disclosure of Customer's Confidential Information containing personal data, and any notifications relating to any such disclosures, will be made in accordance with such Model Contract Clauses.</p>
                </li>
                <br/>
                <li>
                    <p><b>Sub-processors.</b></p>
                    <p>11.1. <b>Consent to Sub Processor Engagement</b></p>
                    <p>Customer specifically authorizes the engagement of Company’s Affiliates as Sub-processors. In addition, Customer generally authorizes the engagement of any other third parties as Sub processors (“<b>Third Party Sub processors</b>”). If Customer has entered into Model Contract Clauses as described in Section 10.2 (Transfers of Data Out of the EEA), the above authorizations will constitute Customer’s prior written consent to the subcontracting by Company of the processing of Customer Data if such consent is required under the Model Contract Clauses.</p>
                    <p>11.2. <b>Information about Sub processors</b></p>
                    <p>Information about Sub processors, including their roles and locations, is available on request.</p>
                    <p>11.3. <b>Requirements for Sub Processor Engagement</b></p>
                    <p>When engaging any Sub processor, Company will:</p>
                    <p>(a) ensure via a written contract that:</p>
                    <p>(i) the Sub processor only accesses and uses Customer Data to the extent required to perform the obligations subcontracted to it, and does so in accordance with the applicable Master Agreement (including this DPA) and any Model Contract Clauses entered into by Company as described in Section 10.2 (Transfers of Data Out of the EEA); and</p>
                    <p>(ii) if the GDPR applies to the processing of Customer Personal Data, the data protection obligations set out in Article 28(3) of the GDPR, as described in this Data Processing Amendment, are imposed on the Sub processor; and</p>
                    <p>(b) remain fully liable for all obligations subcontracted to, and all acts and omissions of, the Sub processor.</p>
                    <p>11.4 <b>Opportunity to Object to Sub Processor Changes.</b></p>
                    <p>(a) When any new Third Party Sub processor is engaged during the applicable Term, Company will, at least 10 days before the new Third Party Sub processor processes any Customer Data, inform Customer of the engagement (including the name and location of the relevant sub processor and the activities it will perform) either by sending an email to the Notification Email Address.</p>
                    <p>(b) Customer may object to any new Third Party Sub processor by terminating the applicable Master Agreement immediately upon written notice to Company, on condition that Customer provides such notice within 30 days of being informed of the engagement of the sub processor as described in Section 11.4(a). This termination right is Customer’s sole and exclusive remedy if Customer objects to any new Third Party Sub processor.</p>
                </li>
                <br/>
                <li>
                    <p><b>Tracking Technologies.</b></p>
                    <p>Company acknowledges that in connection with the performance of the Services, Company employs the use of cookies, unique identifiers, web beacons and similar tracking technologies ("Tracking Technologies"). Company shall maintain appropriate notice, consent, opt-in and opt-out mechanisms as are required by applicable Data Protection Laws to enable the Company to deploy Tracking Technologies lawfully and collect data from the devices of end users in accordance with and as described in Company’s Cookie Statement. </p>
                </li>
                <br/>
                <li>
                    <p><b>General Terms</b></p>
                    <p>13.1 <b>Governing law and jurisdiction</b></p>
                    <p>Without prejudice to clauses 7 (Mediation and Jurisdiction) and 9 (Governing Law) of the Standard Contractual Clauses:</p>
                    <p>13.1.1 the parties to this DPA hereby submit to the choice of jurisdiction stipulated in the Master Agreement with respect to any disputes or claims howsoever arising under this DPA, including disputes regarding its existence, validity or termination or the consequences of its nullity; and</p>
                    <p>13.1.2 this DPA and all non-contractual or other obligations arising out of or in connection with it are governed by the laws of the country or territory stipulated for this purpose in the Master Agreement. </p>
                    <p>13.2 <b>Changes in Data Protection Laws.</b></p>
                    <p>Company may propose any other variations to this DPA which Company reasonably considers to be necessary to address the requirements of any change in the Data Protection Law.</p>
                    <p>IN WITNESS WHEREOF, this DPA is entered into and becomes a binding part of the Master Agreement with effect from the date first set out above.</p>
                </li>
            </ol>
            <br/>
            <div>
                <p className="has-text-centered has-text-weight-bold">ANNEXURE I</p>
                <p className="has-text-centered has-text-weight-bold">DETAILS OF PROCESSING OF CUSTOMER PERSONAL DATA</p>
                <p className="has-text-centered">This Annex 1 includes certain details of the Processing of Customer Personal Data as required by Article 28(3) GDPR.</p>
                <p><b>Subject Matter</b><br/>
                The subject matter and duration of the processing of Your Personal Data are set out in the Agreement</p>
                <p><b>Duration of Processing</b><br/>Company will Process Personal Data for the duration of the Master Agreement, unless otherwise agreed upon in writing</p>
                <p><b>Nature and purpose of the processing</b><br/>Company will process Personal Data for the purposes of providing the Services to Customer in accordance with the Master Agreement.</p>
                <p><b>Type of personal data and categories of data subject to be processed;</b><br/>
                    <ul>
                        <li>Data relating to individuals provided to Company via the Services, by (or at the direction of) the Customer or by the Customer’s End Users.</li>
                        <li>Data subjects include the individuals about whom data is provided to Company via the Services.</li>
                    </ul>
                </p>
                <p><b>Categories of Data to whom the Company Personal Data relates</b><br/>Customer may submit Personal Data to the Services, the extent of which is determined and controlled by Customer in its sole discretion, and which may include, but is not limited to the following categories of Personal Data: 
                    <ul>
                        <li>First and Last name</li>
                        <li>Contact information (Company, Job Title, email, phone, physical address)</li>
                        <li>IP Address </li>
                    </ul>
                </p>
                <p><b>The obligations and rights of Company and Company Affiliates</b><br/>The obligations and rights of Company and Company’s Affiliates are set out in the Master Agreement and this Addendum.</p>
            </div>
            <br/><br/>
            <div>
                <p className="has-text-centered has-text-weight-bold">ANNEXURE II</p>
                <p className="has-text-centered has-text-weight-bold">Standard Contractual Clauses (processors)</p> 
                <p>For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection</p>
                <p>Name of the data exporting organisation:</p><br/>
                <p>Customer which has agreed this Standard Contractual Clauses as data exporter on behalf of its affiliates or their respective clients and client affiliates in the European Economic Area and Switzerland</p>
                <p>(the data exporter)</p><br/>
                <p>And</p><br/>
                <p>Company which has agreed this Standard Contractual Clauses as a data importer</p>
                <p> (the data importer)</p>
                <p>each a “party”; together “the parties”,</p><br/>
                <p>HAVE AGREED on the following Contractual Clauses (the Clauses) in order to adduce adequate safeguards with respect to the protection of privacy and fundamental rights and freedoms of individuals for the transfer by the data exporter to the data importer of the personal data specified in Appendix 1.</p>
                <p><b>Clause 1 - Definitions</b></p>
                <p>For the purposes of the Clauses:</p>
                <ol type="a">
                    <li>'personal data', 'special categories of data', 'process/processing', 'controller', 'processor', 'data subject' and 'supervisory authority' shall have the same meaning as in Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection of individuals with regard to the processing of personal data and on the free movement of such data;</li>
                    <li>'the data exporter' means the controller who transfers the personal data;</li>
                    <li>'the data importer' means the processor who agrees to receive from the data exporter personal data intended for processing on his behalf after the transfer in accordance with his instructions and the terms of the Clauses and who is not subject to a third country's system ensuring adequate protection within the meaning of Article 25(1) of Directive 95/46/EC;</li>
                    <li>'the subprocessor' means any processor engaged by the data importer or by any other subprocessor of the data importer who agrees to receive from the data importer or from any other subprocessor of the data importer personal data exclusively intended for processing activities to be carried out on behalf of the data exporter after the transfer in accordance with his instructions, the terms of the Clauses and the terms of the written subcontract;</li>
                    <li>'the applicable data protection law' means the legislation protecting the fundamental rights and freedoms of individuals and, in particular, their right to privacy with respect to the processing of personal data applicable to a data controller in the Member State in which the data exporter is established;</li>
                    <li>'technical and organisational security measures' means those measures aimed at protecting personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing.</li>
                </ol>
                <p><b>Clause 2 - Details of the transfer</b></p>
                <p>The details of the transfer and in particular the special categories of personal data where applicable are specified in Appendix 1 which forms an integral part of the Clauses.</p>
                <p><b>Clause 3 - Third-party beneficiary clause</b></p>
                <ol>
                    <li>The data subject can enforce against the data exporter this Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j), Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party beneficiary. </li>
                    <li>The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where the data exporter has factually disappeared or has ceased to exist in law unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law, as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity. </li>
                    <li>The data subject can enforce against the subprocessor this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses. </li>
                    <li>The parties do not object to a data subject being represented by an association or other body if the data subject so expressly wishes and if permitted by national law. </li>
                </ol>
                <p><b>Clause 4 - Obligations of the data exporter</b></p>
                <p>The data exporter agrees and warrants: </p>
                <ol type="a">
                    <li>that the processing, including the transfer itself, of the personal data has been and will continue to be carried out in accordance with the relevant provisions of the applicable data protection law (and, where applicable, has been notified to the relevant authorities of the Member State where the data exporter is established) and does not violate the relevant provisions of that State;</li>
                    <li>that it has instructed and throughout the duration of the personal data processing services will instruct the data importer to process the personal data transferred only on the data exporter's behalf and in accordance with the applicable data protection law and the Clauses;</li>
                    <li>that the data importer will provide sufficient guarantees in respect of the technical and organisational security measures specified in Appendix 2 to this contract;</li>
                    <li>that after assessment of the requirements of the applicable data protection law, the security measures are appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing, and that these measures ensure a level of security appropriate to the risks presented by the processing and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;</li>
                    <li>that it will ensure compliance with the security measures;</li>
                    <li>that, if the transfer involves special categories of data, the data subject has been informed or will be informed before, or as soon as possible after, the transfer that its data could be transmitted to a third country not providing adequate protection within the meaning of Directive 95/46/EC;</li>
                    <li>to forward any notification received from the data importer or any subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data protection supervisory authority if the data exporter decides to continue the transfer or to lift the suspension;</li>
                    <li>to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2, and a summary description of the security measures, as well as a copy of any contract for subprocessing services which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information, in which case it may remove such commercial information;</li>
                    <li>that, in the event of subprocessing, the processing activity is carried out in accordance with Clause 11 by a subprocessor providing at least the same level of protection for the personal data and the rights of data subject as the data importer under the Clauses; and</li>
                    <li>that it will ensure compliance with Clause 4(a) to (i).</li>
                </ol>
                <p><b>Clause 5 - Obligations of the data importer</b></p>
                <p>The data importer agrees and warrants:</p>
                <ol type="a">
                    <li>to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;</li>
                    <li>that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the instructions received from the data exporter and its obligations under the contract and that in the event of a change in this legislation which is likely to have a substantial adverse effect on the warranties and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;</li>
                    <li>that it has implemented the technical and organisational security measures specified in Appendix 2 before processing the personal data transferred;</li>
                    <li>that it will promptly notify the data exporter about:</li>
                    <ol type="i">
                        <li>any legally binding request for disclosure of the personal data by a law enforcement authority unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation,</li>
                        <li>any accidental or unauthorised access, and</li>
                        <li>any request received directly from the data subjects without responding to that request, unless it has been otherwise authorised to do so;</li>
                    </ol>
                    <li>to deal promptly and properly with all inquiries from the data exporter relating to its processing of the personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the processing of the data transferred;</li>
                    <li>at the request of the data exporter to submit its data processing facilities for audit of the processing activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed of independent members and in possession of the required professional qualifications bound by a duty of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;</li>
                    <li>to make available to the data subject upon request a copy of the Clauses, or any existing contract for subprocessing, unless the Clauses or contract contain commercial information, in which case it may remove such commercial information, with the exception of Appendix 2 which shall be replaced by a summary description of the security measures in those cases where the data subject is unable to obtain a copy from the data exporter;</li>
                    <li>that, in the event of subprocessing, it has previously informed the data exporter and obtained its prior written consent;</li>
                    <li>that the processing services by the subprocessor will be carried out in accordance with Clause 11;</li>
                    <li>to send promptly a copy of any subprocessor agreement it concludes under the Clauses to the data exporter.</li>
                </ol>
                <p><b>Clause 6 - Liability</b></p>
                <ol>
                    <li>The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations referred to in Clause 3 or in Clause 11 by any party or subprocessor is entitled to receive compensation from the data exporter for the damage suffered.</li>
                    <li>If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data exporter, arising out of a breach by the data importer or his subprocessor of any of their obligations referred to in Clause 3 or in Clause 11, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract of by operation of law, in which case the data subject can enforce its rights against such entity.<br/>The data importer may not rely on a breach by a subprocessor of its obligations in order to avoid its own liabilities.</li>
                    <li>If a data subject is not able to bring a claim against the data exporter or the data importer referred to in paragraphs 1 and 2, arising out of a breach by the subprocessor of any of their obligations referred to in Clause 3 or in Clause 11 because both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, the subprocessor agrees that the data subject may issue a claim against the data subprocessor with regard to its own processing operations under the Clauses as if it were the data exporter or the data importer, unless any successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law, in which case the data subject can enforce its rights against such entity. The liability of the subprocessor shall be limited to its own processing operations under the Clauses.</li>
                </ol>
                <p><b>Clause 7 - Mediation and jurisdiction</b></p>
                <ol>
                    <li>The data importer agrees that if the data subject invokes against it third-party beneficiary rights and/or claims compensation for damages under the Clauses, the data importer will accept the decision of the data subject:</li>
                    <ol type="a">
                        <li>to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory authority;</li>
                        <li>to refer the dispute to the courts in the Member State in which the data exporter is established.</li>
                    </ol>
                    <li>The parties agree that the choice made by the data subject will not prejudice its substantive or procedural rights to seek remedies in accordance with other provisions of national or international law.</li>
                </ol>
                <p><b>Clause 8 - Cooperation with supervisory authorities</b></p>
                <ol>
                    <li>The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests or if such deposit is required under the applicable data protection law.</li>
                    <li>The parties agree that the supervisory authority has the right to conduct an audit of the data importer, and of any subprocessor, which has the same scope and is subject to the same conditions as would apply to an audit of the data exporter under the applicable data protection law.</li>
                    <li>The data importer shall promptly inform the data exporter about the existence of legislation applicable to it or any subprocessor preventing the conduct of an audit of the data importer, or any subprocessor, pursuant to paragraph 2. In such a case the data exporter shall be entitled to take the measures foreseen in Clause 5 (b).</li>
                </ol>
                <p><b>Clause 9 - Governing Law</b></p>
                <p>The Clauses shall be governed by the law of the Member State in which the data exporter is established.</p>
                <p><b>Clause 10 - Variation of the contract</b></p>
                <p>The parties undertake not to vary or modify the Clauses. This does not preclude the parties from adding clauses on business related issues where required as long as they do not contradict the Clause.</p>
                <p><b>Clause 11 - Subprocessing</b></p>
                <p>
                    <ol>
                        <li>The data importer shall not subcontract any of its processing operations performed on behalf of the data exporter under the Clauses without the prior written consent of the data exporter. Where the data importer subcontracts its obligations under the Clauses, with the consent of the data exporter, it shall do so only by way of a written agreement with the subprocessor which imposes the same obligations on the subprocessor as are imposed on the data importer under the Clauses. Where the subprocessor fails to fulfil its data protection obligations under such written agreement the data importer shall remain fully liable to the data exporter for the performance of the subprocessor's obligations under such agreement. </li>
                        <li>The prior written contract between the data importer and the subprocessor shall also provide for a third-party beneficiary clause as laid down in Clause 3 for cases where the data subject is not able to bring the claim for compensation referred to in paragraph 1 of Clause 6 against the data exporter or the data importer because they have factually disappeared or have ceased to exist in law or have become insolvent and no successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.</li>
                        <li>The provisions relating to data protection aspects for subprocessing of the contract referred to in paragraph 1 shall be governed by the law of the Member State in which the data exporter is established.</li>
                        <li>The data exporter shall keep a list of subprocessing agreements concluded under the Clauses and notified by the data importer pursuant to Clause 5 (j), which shall be updated at least once a year. The list shall be available to the data exporter's data protection supervisory authority.</li>
                    </ol>
                </p>
                <p><b>Clause 12 - Obligation after the termination of personal data processing services</b></p>
                <p>
                    <ol>
                        <li>The parties agree that on the termination of the provision of data processing services, the data importer and the subprocessor shall, at the choice of the data exporter, return all the personal data transferred and the copies thereof to the data exporter or shall destroy all the personal data and certify to the data exporter that it has done so, unless legislation imposed upon the data importer prevents it from returning or destroying all or part of the personal data transferred. In that case, the data importer warrants that it will guarantee the confidentiality of the personal data transferred and will not actively process the personal data transferred anymore.</li>
                        <li>The data importer and the subprocessor warrant that upon request of the data exporter and/or of the supervisory authority, it will submit its data processing facilities for an audit of the measures referred to in paragraph 1.</li>
                    </ol>
                </p>
                <p><b>Clause 13 - Liability</b></p>
                <p>The parties agree that if one party is held liable for a violation of the clauses committed by the other party, the latter will, to the extent to which it is liable, indemnify the first party for any cost, charge, damages, expenses or loss it has incurred.  Indemnification is contingent upon:</p>
                <ol type="a">
                    <li>the data exporter promptly notifying the data importer of a claim; and</li>
                    <li>the data importer being given the possibility to cooperate with the data exporter in the defence and settlement of the claim</li>
                </ol>
                <br/>
                <p className="has-text-centered"><b>APPENDIX 1 TO THE STANDARD CONTRACTUAL CLAUSES</b></p>
                <p>This Appendix forms part of the Clauses.</p>
                <p>The Member States may complete or specify, according to their national procedures, any additional necessary information to be contained in this Appendix.</p>
                <p><b>Data exporter</b></p>
                <p>Data Exporter is a Customer that uses Data Importer’s products and shared personal data with Data importer.</p>
                <p><b>Data importer</b></p>
                <p>Data Importer is a company that provides products and services to Data Exporter.</p>
                <p><b>Data subjects</b></p>
                <p>The personal data transferred concern the following categories of data subjects:</p>
                <ul>
                    <li>Data relating to individuals provided to Company via the Services, by (or at the direction of) the Customer or by the Customer’s End Users.</li>
                    <li>Data subjects include the individuals about whom data is provided to Company via the Services.</li>
                </ul>
                <p><b>Categories of data</b></p>
                <p>Customer may submit Personal Data to the Services, the extent of which is determined and controlled by Customer in its sole discretion, and which may include, but is not limited to the following categories of Personal Data:</p>
                <ul>
                    <li>First and Last name</li>
                    <li>Contact information (Company, Job Title, email, phone, physical address)</li>
                    <li>IP Address</li>
                </ul>
                <p><b>Special categories of data (if appropriate)</b></p>
                <p>The personal data transferred does not include any sensitive data</p>
                <p><b>Processing operations</b></p>
                <p>The personal data transferred will be subject to the following basic processing activities:</p>
                <p>Data importer will process personal data as necessary to perform the services pursuant to the Agreement. The processing operations performed on the personal data will depend on the scope of data exporter's services. Such services may include:</p>
                <ul>
                    <li>Collecting, Recording, Organising, Storage, Use, Alteration, Disclosure, Transmission, Retrieval, Destruction, Archival</li>
                </ul>
                <br/>
                <p className="has-text-centered"><b>APPENDIX 2 TO THE STANDARD CONTRACTUAL CLAUSES</b></p>
                <p>This Appendix forms part of the Clauses.</p>
                <p><b>Description of the technical and organisational security measures implemented by the data importer in accordance with Clauses 4(d) and 5(c) (or document/legislation attached):</b></p>
                <p>The technical and organisational security measures implemented by the data importer are as described below, and as further specified in any underlying Agreements:</p>
                <p>Data Importer maintains a written security program for the security, integrity and protection of personal data it processes on behalf of its customers against unauthorised disclosure and loss. Data Importer's security program includes administrative, technical and physical safeguards appropriate for data importer's size and resources and the types of information that it processes.</p>
            </div>
            <br/>
            <p>If you have any questions or would like a signed copy of the DPA, you may contact us at: <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a><br/>
            <br/><b>DPA was last updated on January 11, 2020</b></p>
            </div>
    </div>
    <AmberScore/>
  </Layout>
)

export default DataProtection

